@import 'style';

$color: #3f97bd;

.outercontainer {
	width: 100%;
	position: relative;

	@include breakpoint('ipad-land') {
		margin: 0 auto;
		max-width: calc(100% - 120px);
	}

	@media only screen and (min-width: 1280px) {
		// max-width: 1160px;

		&.mod-big {
			max-width: 1280px;
		}
	}

	@media only screen and (min-width: 1600px) {
		max-width: 1480 * $px;
	}

	@media only screen and (min-width: 1720px) {
		max-width: 1580 * $px;
	}
}

.container {
	position: relative;
	width: 100%;
	margin: 0 auto;
	max-width: calc(100% - 50px);

	@include breakpoint('ipad-land') {
		max-width: calc(100% / (1160 / 960));
	}

	:global(.mod-fullwidth) & {
		max-width: 100%;
	}

	&-grid {
		pointer-events: none;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&-item {
			background: rgba(#00ffff, 0.25);
		}
	}
}

.h1,
.h2,
.h3,
.h4,
.h5 {
	word-wrap: break-word;
	white-space: pre-line;

	&.mod-light {
		font-weight: 300;
	}

	&.mod-medium {
		font-weight: 500;
	}

	&.mod-blueline {
		&:before {
			content: '';
			width: 25px;
			height: 5px;
			display: block;
			background-color: $blue;
			margin-bottom: 20px;
		}
	}

	&.mod-arrow {
		&:after {
			content: '';
			background: url('~assets/images/arrow-right-title.svg') no-repeat;
			width: 22px;
			height: 18px;
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			margin-top: -10px;
			transition: transform 0.25s ease-out;
		}

		&:hover {
			&:after {
				transform: translateX(2.5 * $px);
			}
		}
	}

	&.mod-blueline.mod-white:before {
		background-color: white;
	}

	&.mod-arrow.mod-white:after {
		background: url('~assets/images/arrow-right-title-white.svg') no-repeat;
	}

	&.mod-toggle {
		&:after {
			content: '';
			background: url('~assets/images/chevron-down.svg') no-repeat;
			width: 10px;
			height: 4.52px;
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			transition: transform 0.25s ease-out;
		}
	}
}

.h1 {
	font-size: 32 * $px;
	line-height: 1.25;
	font-weight: 300;

	@include breakpoint('ipad-port') {
		font-size: 36 * $px;
	}
}

.h2 {
	font-size: 28 * $px;
	line-height: 1.5;
	font-weight: 300;

	@include breakpoint('ipad-port') {
		font-size: 32 * $px;
	}
}

.h3 {
	font-size: 24 * $px;
	line-height: 1.5;
	font-weight: 500;
}

.h4 {
	font-size: 20 * $px;
	line-height: 1.5;
	font-weight: 600;
}

.h5 {
	font-size: 18 * $px;
	line-height: 1.5;
	font-weight: 500;

	&.mod-blueline {
		&:before {
			content: '';
			width: 30px;
			height: 4px;
			display: inline-block;
			vertical-align: middle;
			background-color: $blue;
			margin-bottom: 0;
			margin-right: 20 * $px;
		}
	}
}

.wysiwyg {
	word-wrap: break-word;

	hr {
		border: none;
		background-color: #eaeaea;
		height: 1px;
		margin: 45px 0;
	}

	h2 {
		@extend .h2;

		&:before {
			content: '';
			width: 25px;
			height: 5px;
			display: block;
			background-color: $blue;
			margin-bottom: 30px;
		}

		.mod-icon & {
			&:before {
				content: none;
			}
		}
	}

	h3 {
		@extend .h3;
	}

	h4 {
		@extend .h4;
	}

	h5 {
		@extend .h5;
	}

	&.mod-title-line {
		h4,
		h5 {
			&:before {
				content: '';
				width: 30px;
				height: 4px;
				display: inline-block;
				vertical-align: middle;
				background-color: $blue;
				margin-right: 20px;
			}
		}
	}

	h2 {
		& + p,
		& + ul,
		& + ol,
		& + :global(.wysiwyg-table),
		& + blockquote {
			margin-top: 30 * $px;
		}
	}

	h3,
	h4,
	h5 {
		& + p,
		& + ul,
		& + ol,
		& + :global(.wysiwyg-table),
		& + blockquote {
			margin-top: 15 * $px;
		}
	}

	h2,
	h3,
	h4,
	h5,
	p,
	ul,
	ol,
	:global(.wysiwyg-table),
	blockquote {
		& + h2,
		& + h3,
		& + h4,
		& + h5 {
			margin-top: 30 * $px;

			.mod-icon & {
				margin-top: 10 * $px;
			}
		}
	}

	p {
		line-height: inherit !important;

		& + ul,
		& + ol {
			margin-top: 15 * $px;
		}

		& + p,
		& + blockquote {
			margin-top: 30 * $px;

			.mod-icon & {
				margin-top: 5 * $px;
			}
		}

		& + :global(.wysiwyg-table) {
			margin-top: 50 * $px;
		}
	}

	ul,
	ol {
		margin-left: 15 * $px;

		li + li {
			margin-top: 5 * $px;
		}

		& + p,
		& + ul,
		& + ol,
		& + blockquote {
			margin-top: 30 * $px;
		}

		& + :global(.wysiwyg-table) {
			margin-top: 50 * $px;
		}
	}

	ul {
		list-style: none !important;
		margin-left: 0;

		li {
			position: relative;
			padding-left: 25 * $px;

			&:before {
				content: '•';
				color: $color;
				position: absolute;
				left: 0;
			}
		}
	}

	table {
		max-width: 100%;
		border-collapse: collapse;

		tbody tr:nth-child(even) {
			background-color: #f2f2f2;
		}

		th,
		td {
			padding: 10 * $px 15 * $px;
			border: 1px solid #ddd;
		}
	}

	blockquote {
		color: $color;
		font-weight: 700;
		font-style: italic;
		border-left: 3px solid $color;
		padding: 15 * $px;

		b,
		strong {
			color: $text;
		}

		& + p,
		& + ul,
		& + ol,
		& + blockquote {
			margin-top: 35 * $px;
		}
	}

	a {
		color: $color;
		display: inline;
		font-weight: 500;
		border-bottom: 1px solid transparent;
		transition: all 0.25s ease-out;

		&:hover {
			color: lighten($color, 5%);
			&:not(:global(.btn)):not(:global(.link)) {
				border-color: currentColor;
			}
		}
	}

	img {
		height: auto;
		display: block;
		max-width: 100%;
	}

	iframe {
		max-width: 100%;
	}

	&.mod-inherit {
		a,
		p {
			color: inherit;
		}
	}
}

.trustedtext {
	p {
		line-height: inherit !important;
	}

	a {
		color: $color;
		display: inline;
		border-bottom: 1px solid transparent;
		transition: all 0.25s ease-out;

		&:hover {
			color: lighten($color, 5%);
			border-color: lighten($color, 5%);
		}
	}
}

.button {
	cursor: pointer;
	color: white;
	line-height: 1.5;
	font-weight: 500;
	display: inline-block;
	vertical-align: top;
	padding: 8 * $px 12 * $px;
	background-color: $color;
	border: 1px solid transparent;
	transition: all 0.25s ease-out;

	&:hover {
		background-color: lighten($color, 5%);
	}

	//MODS
	&.mod-outline {
		color: $color;
		border-color: rgba($color, 0.5);
		background-color: transparent;

		&:hover {
			color: white;
			background-color: $color;
		}
	}

	&.mod-rounded {
		border-radius: 3 * $px;
	}

	&.mod-round {
		padding: 8 * $px 25 * $px;
		border-radius: 50 * $px;
	}

	&.mod-white {
		color: $text;
		background-color: white;

		&:hover {
			color: white;
			background-color: $color;
		}
	}

	&.mod-outline.mod-white {
		color: white;
		border-color: white;
		background-color: transparent;

		&:hover {
			color: #3f97bd;
			background-color: white;
		}
	}
}

.buttonicon {
	font-size: 12 * $px;
	display: inline-block;
	vertical-align: middle;
}

.link {
	color: $color;
	font-weight: 500;
	display: inline-block;
	vertical-align: middle;
	transition: all 0.25s ease-out;

	&:hover {
		.linkicon {
			transform: translateX(5px);
		}
	}

	&.mod-inherit {
		color: inherit;
	}
}

.linkicon {
	font-size: 12 * $px;
	display: inline-block;
	vertical-align: middle;
	backface-visibility: hidden;
	transition: all 0.25s ease-out;
}

.buttontext,
.buttonicon,
.linktext,
.linkicon {
	& + & {
		margin-left: 10 * $px;
	}
}

.p {
	position: relative;
}
