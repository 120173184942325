@import 'style';

.articles {
	overflow: hidden;

	&-top {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
		margin-top: -25 * $px;
		margin-left: -25 * $px;

		&-title,
		&-nav {
			margin-top: 25 * $px;
			padding-left: 25 * $px;
		}
	}

	&-controls {
		text-align: right;
		margin-top: 45 * $px;
	}
}

.subjects {
	margin-top: 40px;

	&-list {
		overflow: hidden;
		list-style: none;

		@include breakpoint('tablet') {
			display: flex;
			flex-wrap: wrap;
			margin-left: -50px;
		}

		&-item {
			margin-top: -1px;

			@include breakpoint('tablet') {
				display: inline-block;
				vertical-align: top;
				width: calc(100% / 2);
				padding-left: 50px;
			}

			@include breakpoint('ipad-port') {
				width: calc(100% / 3);
			}

			&-tag {
				padding: 20 * $px 0;
				border-top: 1px solid $line;

				&-label {
					border-bottom: 1px solid transparent;
					transition: border 0.25s ease-out;

					@include breakpoint('ipad-land') {
						font-size: 18 * $px;
						line-height: (30/18);
					}

					&:hover {
						border-color: $dark-grey;
					}
				}
			}
		}
	}
}

.content {
	margin-top: 40 * $px;
	padding-top: 75 * $px;

	&-background {
		color: white;
		background-color: $dark-grey;
		padding-bottom: 55 * $px;
	}

	&-items {
		@include gridMarginLeft(0, -1);

		&-item {
			min-width: 100%;

			@include gridPaddingLeft(0, 1);
			margin-top: -75 * $px;

			@include breakpoint('small') {
				width: calc(100% / 2);
				min-width: calc(100% / 2);
			}

			@include breakpoint('ipad-port') {
				width: calc(100% / 3);
				min-width: calc(100% / 3);
			}
		}
	}
}

.contentextra {
	display: block;
	position: relative;
	background-color: $blue;
	width: 100%;
	aspect-ratio: 16/9;
	transform: translateY(0);
	transition: transform 0.25s ease-out;
	box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);

	&:hover {
		@include breakpoint('desktop') {
			transform: translateY(-20 * $px);
		}
	}

	&-content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 15 * $px;

		&-icon {
			display: block;
			font-size: 80 * $px;
		}

		&-icon + &-title {
			margin-top: 20 * $px;
		}

		&-title {
			text-align: center;
		}
	}
}
